import { ROLE } from './api';

export const APP_MENU_DEFAULTS = {
  '/expenses': {
    label: 'Gastos',
    icon: 'fa-solid fa-receipt',
  },
  '/expenseSheets': {
    label: 'Hojas de gastos',
    icon: 'fa-solid fa-file-invoice-dollar',
  },
  '/projects': {
    label: 'Proyectos',
    icon: 'fa-solid fa-diagram-project',
  },
  '/businesses': {
    label: 'Clientes',
    icon: 'fa-solid fa-user-tie',
  },
  '/providers': {
    label: 'Proveedores',
    icon: 'fa-solid fa-user-tie',
  },
  '/paymentMethods': {
    label: 'Métodos de pago',
    icon: 'fa-solid fa-credit-card',
  },
  '/tags': {
    label: 'Tags',
    key: 'tags',
    icon: 'fa-solid fa-tags',
  },
  '/currencyCodes': {
    label: 'Divisas',
    icon: 'fa-solid fa-dollar-sign',
  },
  '/supervisors': {
    label: 'Supervisores',
    icon: 'group',
    roles: [ROLE.S_ADMIN],
  },
  '/centers': {
    label: 'Centros de trabajo',
    icon: 'fa-solid fa-building',
    roles: [ROLE.S_ADMIN],
  },
  '/subcompanies': {
    label: 'Unidades de negocio',
    icon: 'fa-solid fa-briefcase',
    roles: [ROLE.S_ADMIN],
  },
  '/groups': {
    label: 'Grupos',
    icon: 'group',
    roles: [ROLE.S_ADMIN],
  },
  '/emailTemplate': {
    label: 'Plantillas de correo',
    icon: 'email',
    roles: [ROLE.S_ADMIN],
  },
  '/customObjectType': {
    label: 'Maestros personalizados',
    icon: 'email',
    roles: [ROLE.S_ADMIN],
  },
  '/signUps': {
    label: 'Control de accesos',
    icon: 'fa-solid fa-users',
  },
  '/vacationControl': {
    label: 'Control de vacaciones',
    icon: 'fa-solid fa-mug-hot',
  },
  '/travelControl': {
    label: 'Control de viajes',
    icon: 'fa-solid fa-plane',
  },
  '/users': {
    label: 'Usuarios',
    icon: 'fa-solid fa-users',
    roles: [ROLE.S_ADMIN],
  },
  '/settings': {
    label: 'Ajustes',
    icon: 'fa-solid fa-gear',
    roles: [ROLE.S_ADMIN],
  },
  masters: {
    label: 'Maestros',
    icon: 'fa-solid fa-folder-tree',
  },
  travelAndVacation: {
    label: 'Viajes y vacaciones',
    icon: 'fa-solid fa-compass',
  },
};

export const APP_MENU_STRUCTURE = [
  '/expenses',
  '/expenseSheets',
  {
    key: 'masters',
    items: [
      '/projects',
      '/businesses',
      '/providers',
      '/paymentMethods',
      '/tags',
      '/currencyCodes',
      '/supervisors',
      '/centers',
      '/subcompanies',
      '/groups',
      '/emailTemplate',
      '/customObjectType',
    ],
  },
  {
    key: 'travelAndVacation',
    items: ['/vacationControl', '/travelControl'],
  },
  '/signUps',
  '/users',
  '/settings',
];
