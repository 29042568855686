import { TreeView } from 'devextreme-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { AppMenuWrapper } from '../styled';
import { useAppDataSource, useProfile } from '../../store';
import { MODEL } from '../../constants/models';
import { APP_MENU_DEFAULTS, APP_MENU_STRUCTURE } from '../../constants/appRoutes';

export const AppMenu = () => {
  const profile = useProfile();
  const navigate = useNavigate();
  const items = useMenuItems();

  const handleClickItem = useCallback(
    (event) => {
      const { component, node, itemElement: item, itemData: data } = event;
      const isExpanded = node.expanded;
      const { path } = data;

      if (path) {
        navigate(path);
        const id = 'app-menu-selected';
        const currentSelected = document.getElementById(id);

        if (currentSelected) {
          currentSelected.id = undefined;
        }

        item.id = id;
      } else {
        if (isExpanded) {
          component.collapseItem(item);
        } else {
          component.expandItem(item);
        }
      }
    },
    [navigate]
  );

  if (!profile) {
    return <></>;
  }

  return (
    <AppMenuWrapper>
      <TreeView
        focusStateEnabled={false}
        activeStateEnabled={false}
        items={items}
        displayExpr="label"
        keyExpr={'key'}
        onItemClick={handleClickItem}
      />
    </AppMenuWrapper>
  );
};

const useMenuItems = () => {
  const profile = useProfile();
  const routeConfig = useAppDataSource(MODEL.ROUTE).filter((x) => x.app === 'ADMIN_PANEL');

  const getItemConfig = (item) => {
    if (typeof item === 'object') {
      const defaultConfig = APP_MENU_DEFAULTS[item.key];
      const items = item.items.map(getItemConfig);

      return {
        key: item.key,
        label: defaultConfig.label,
        items,
        visible: items.some((x) => x.visible),
        icon: defaultConfig.icon,
      };
    }

    const defaultConfig = APP_MENU_DEFAULTS[item];
    const customConfig = routeConfig.find((x) => x.key === item);

    const label = customConfig?.title || defaultConfig.label;
    const roles = customConfig?.roles ?? defaultConfig?.roles ?? [];
    const isVisibleByRole = roles.length === 0 || roles.some((role) => role === profile?.role);
    const visible = isVisibleByRole && customConfig?.showInMenu !== false;

    return {
      key: item,
      path: item,
      label,
      visible,
      icon: defaultConfig.icon,
    };
  };

  return APP_MENU_STRUCTURE.map(getItemConfig);
};
